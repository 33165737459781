import * as React from "react";
import NavItemDivWrapper from "./nav-item-div-wrapper.jsx";
import styles from "./sidenav.module.css";
import strings from "../scripts/strings.js";

const IDS = {
    area: { id: "sideNav" }
}

const SideNav = (props) => {
    return(
        <div id = { IDS.area.id } className = { styles.sideNavContainer }>
            <div>
                <button className = "btnClose" aria-label = "Close" onClick = { (e) => props.handlers["sideNavClose"](e) }>&#215;</button>
            </div>
            { 
                Object.keys(strings.navLabels).map((key, index) => (NavItemDivWrapper(strings.navLabels[key], index, { click: props.handlers["sideNavClick"] }, props.selectedOption)))
            }
        </div>
    )
}
            
export default SideNav