import { Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./search-area.module.css";

const IDS = {
    "area": { "id": "searchArea" },
    "group": { "id": "searchGroup" },
    "input": { "id": "searchInput" }
}

const SearchArea = ({ isSearchDisabled, searchValue, setSearchValue, strings }) => {
    const searchKeyDownHandler = (e) => {
      /**
       * The `searchKeyDownHandler` handles key down events.
       * 
       * @param {object} `e`: The event object.
       * @return void
       * @throw {Error} `err`
       */
      try {
        if (e.key === "Enter") {
          searchSubmitHandler({}); // Call the submit handler method with empty event object
        }
        if (e.key === "Backspace") {
          // pass
        }
      } catch (err) {
        console.error(err);
      }
    }

    const searchInputHandler = (e) => {
      /**
       * The `searchInputHandler` processes search bar events.
       * 
       * @param {object} `e`: The event object.
       * @return void
       * @throw {Error} `err`
       */
      try {     
        e.stopPropagation();
        if ("focus" === e.type) {
          setSearchValue("");
          document.getElementById(IDS.input.id).value = "";
        }
        if ("blur" === e.type) {
          let _inputValue = document.getElementById(IDS.input.id).value;
          if (_inputValue === "") {
            setSearchValue(strings.search.input.label);
          }
        }
        if ("input" === e.type) {
          // pass
        }
      } catch (err) {
        console.error(err);
      }
    }

    const searchSubmitHandler = (e) => {     
      /**
       * The `searchSubmitHandler` processes search input submissions.
       * 
       * @param {object} `e`: The event object.
       * @return void
       * @throw {Error} `err`
       */
      try {
        let _inputValue = document.getElementById(IDS.input.id).value;
        setSearchValue(_inputValue); 
      } catch (err) {
        console.error(err);
      }
    }

    useEffect(() => {
      // Reset the search bar if disabled
      if (isSearchDisabled) {
        document.getElementById(IDS.input.id).value = strings.search.input.label;
        setSearchValue(strings.search.input.label);
      }
    }, [isSearchDisabled]);

    return(
      <Fragment>
        <div className = "container-fluid">
          <div id = { IDS.area.id } className = { ["row", styles.searchAreaContainer].join(" ") }>
            <div className = "col-md-5 ps-0 pe-0">
              <div id = { IDS.group.id } className = "input-group mb-3 mt-3">
                  <button className = "btn btn-outline-secondary" type = "button">
                      <FontAwesomeIcon icon = "fa-solid fa-rectangle-list"></FontAwesomeIcon>
                  </button>
                  <input id = { IDS.input.id } type = "text" autoComplete = "off" disabled = { isSearchDisabled } maxLength = "100" className = "form-control" placeholder = { searchValue } aria-label = { searchValue } onBlur = { (e) => searchInputHandler(e) } onFocus = { (e) => searchInputHandler(e) } onInput = { (e) => searchInputHandler(e) } onKeyDown = { (e) => searchKeyDownHandler(e) }></input>
                  <span className = { [styles.inputIcon, "input-group-text"].join(" ") } onClick = { (e) => searchSubmitHandler(e) }><FontAwesomeIcon icon = "fa-solid fa-magnifying-glass"></FontAwesomeIcon></span>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
}

export default SearchArea