import { useEffect, useState } from "react";
import ReactMarkdown from 'react-markdown';
import { StringUtils, WebUtils } from "../scripts/utils";
import styles from "./markdown.module.css";

const DisplayResume = ({ resumeDocData }) => {
    const [resume, setResume] = useState(null);

    let _filename = resumeDocData.slug;

    useEffect(() => {
        // Set the command string to get this article
        let _command_str = '{"action": "get-project", "file": "' + _filename + '"}';

        // Call the articles service
        WebUtils.request(process.env.REACT_APP_WEBSERVICES_URL, _command_str).then((_data) => {
        // Fetched data may have carriage return and newline characters
        _data = StringUtils.cleanCRNL(_data);
        let _dataJSON = JSON.parse(_data);

        setResume(_dataJSON);
        });
    }, [_filename]);

    return(
        <div className = { styles.markdownContainer }>
            <div>
                { null !== resume ? <ReactMarkdown>{ resume.body }</ReactMarkdown> : <div></div> }
            </div>
        </div>
    )
}

export default DisplayResume