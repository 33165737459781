import { Fragment } from "react";
// import { FormSelect } from "react-bootstrap";
import NavItemDivWrapper from "./nav-item-div-wrapper.jsx";
import styles from "./nav.module.css";
import strings from "../scripts/strings.js";

const IDS = {
    area: { id: "navArea" }
}

const Nav = ({selectedOption, handlers}) => {
    // return(
    //     <Fragment>
    //         <div className = { styles.navContainer }>
    //             <nav className = { styles.desktopNav }>
    //                 {
    //                     Object.keys(strings.navLabels).map((key, index) => (NavItemDivWrapper(strings.navLabels[key], index, {"click": handlers["navClick"]}, selectedOption)))
    //                 }
    //             </nav>
    //         </div>
    //         <FormSelect className = { styles.selectNav }>
    //             {
    //                 Object.keys(strings.navLabels).map((_key, i) => <option key = { `select-nav-option-${i}` }>{ strings.navLabels[_key] }</option>)
    //             }                    
    //         </FormSelect>
    //     </Fragment>
    // )
    return(
        <Fragment>
            <div id = { IDS.area.id }className = { styles.navContainer }>
                <nav className = { styles.desktopNav }>
                    {
                        Object.keys(strings.navLabels).map((key, index) => (NavItemDivWrapper(strings.navLabels[key], index, {"click": handlers["navClick"]}, selectedOption)))
                    }
                </nav>
            </div>
        </Fragment>
    )
}

export default Nav