import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { ObjectUtils, WebUtils } from "../scripts/utils.js";
import Layout from "./layout.jsx";
import Header from "./header.jsx";
import SideNav from "./sidenav.jsx";
import Hr from "./hr.jsx";
import Nav from "./nav.jsx";
import Search from "./search-area.jsx";
import Cards from "./cards.jsx";
import DisplayResume from "./display-resume.jsx";
import DisplayProject from "./display-project.jsx";
import strings from "../scripts/strings.js";

const appConfig = {
  "fontSize": 14, // Make sure this is correct; check `:root` element in `index.css`
  "sideNavWidth": 20
}

const App = () => {  
  // Set state.
  const [selectedNavOption, setSelectedNavOption] = useState(0); // This isn't used right now
  const [selectedNavOptionLabel, setSelectedNavOptionLabel] = useState(strings.navLabels.nav1);
  const [isSearchDisabled, setIsSearchDisabled] = useState(true);
  const [projects, setProjects] = useState(null);
  const [resumeDocData, setResumeDocData] = useState(null);
  const [searchValue, setSearchValue] = useState(strings.search.input.label);

  const navOptionClickHandler = (e) => {      
    // Fetch the nav option that the user clicked on.
    let clickedNavOption = e.target.innerText.toLowerCase();
    let clickedNavOptionIndex = (ObjectUtils.objectIndex(strings.navLabels, null, clickedNavOption));
    
    // Update selectedNav.
    setSelectedNavOption(clickedNavOptionIndex);
    setSelectedNavOptionLabel(clickedNavOption);

    if ("resume" === clickedNavOption) {
      setIsSearchDisabled(true);
    } else {
      setIsSearchDisabled(false);
    }
  }
  
  const sideNavOpenHandler = (e) => {
    const sideNav = document.getElementById("sideNav");
    
    if("click" === e.type){        
        let sideNavPixelWidth = appConfig["fontSize"] * appConfig["sideNavWidth"];
        let windowWidth = window.outerWidth;
        sideNav.style.left = (windowWidth - sideNavPixelWidth) + "px";
    }
  }

  const sideNavCloseHandler = (e) => {
    const sideNav = document.getElementById("sideNav");
    sideNav.style.left = "100%";
  }

  const sideNavOptionClickHandler = (e) => {      
    // Fetch the sideNav option that the user clicked on.
    let clickedSideNavOption = e.target.innerText.toLowerCase();
    let clickedSideNavOptionIndex = (ObjectUtils.objectIndex(strings.navLabels, null, clickedSideNavOption));
    
    // Close the side menu
    sideNavCloseHandler();

    // Update selectedNav.
    setSelectedNavOption(clickedSideNavOptionIndex);
    setSelectedNavOptionLabel(clickedSideNavOption);

    if ("resume" === clickedSideNavOption) {
      setIsSearchDisabled(true);
    } else {
      setIsSearchDisabled(false);
    }    
  }

  useEffect(() => {
    let _action = {
      "action": "get-projects"
    }
    _action = JSON.stringify(_action);
    WebUtils.request(process.env.REACT_APP_WEBSERVICES_URL, _action)
    .then((data) => {
      let _projects = JSON.parse(data);
      
      // DEBUG
      console.log(_projects);
      // DEBUG

      // Set `resumeDocData`
      setResumeDocData(_projects["resume"]);

      // Remove resume document from `_projects`
      delete _projects["resume"];

      // Set `_projects`
      setProjects(_projects);
    })
  }, [])

  return(
    <Routes>
      <Route path = { process.env.REACT_APP_PATH } element = { <Layout></Layout> }>
        <Route index element = {
          <React.Fragment>
            <Header handlers = {{ "sideNavOpen": sideNavOpenHandler }}></Header>
            <SideNav selectedOption = { selectedNavOption } handlers = {{ "sideNavClose": sideNavCloseHandler, "sideNavClick": sideNavOptionClickHandler }}></SideNav>
            <Hr></Hr>
            <Nav selectedOption = { selectedNavOption } handlers = {{ "navClick": navOptionClickHandler }}></Nav>
            <Search isSearchDisabled = { isSearchDisabled } searchValue = { searchValue } setSearchValue = { setSearchValue } strings = { strings} ></Search>
            {
              (null !== resumeDocData && "resume" === selectedNavOptionLabel) && <DisplayResume resumeDocData = { resumeDocData }></DisplayResume>
            }
            {
              (null !== projects && "resume" !== selectedNavOptionLabel) && <Cards selectedNavOptionLabel = { selectedNavOptionLabel } projects = { projects } searchValue = { searchValue }></Cards>
            }          
          </React.Fragment>
        }>
        </Route>
        <Route path = "projects/:slug" element = { <DisplayProject></DisplayProject> }></Route>
      </Route>
    </Routes>
  )  
}

export default App