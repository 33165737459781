import * as React from "react";
import styles from "./header.module.css";

const IDS = {
    menu: { id: "menuIcon" }
}

const Header = (props) => {
    return(
        <div className = { styles.headerContainer }>
            <div className = { styles.logo }>
                <span></span>
            </div>
            <div className = { styles.logoText }>
                <span></span>
            </div>
            <div className = { styles.iconsContainer }>
                <div className = { styles.hamburgerMenu } onClick = { (e) => props.handlers["sideNavOpen"](e) }>
                    <div id = { IDS.menu.id } className = { styles.hamburgerMenuIcon } role = "button" tabIndex = "-1">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header