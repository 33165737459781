import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./card.module.css";
import strings from "../scripts/strings.js";
import { StringUtils } from "../scripts/utils.js";

const Card = ({ selectedNavOptionLabel, cardData, searchValue }) => {
    const navigate = useNavigate();
    let returnThisCard = false;
    
    try {
        if ((searchValue === "") || (searchValue === strings.search.input.label)) {
            returnThisCard = true;
        } else {
            let regex = new RegExp(searchValue, "gi");
            if ((regex.test(cardData.title)) || (regex.test(cardData.body))) {
                returnThisCard = true;
            }
        }
    } catch {
        returnThisCard = true;
    }

    switch (selectedNavOptionLabel) {
        case "ai/nlp":
        case "programming":
            if (returnThisCard) {
                return(
                    <div key = { cardData.slug } className = { ["card", "mb-3", styles.cardContainer].join(" ") } onClick = {() =>{ navigate(`projects/${cardData.slug}`) }}>
                        <img className = "card-img-top" src = { `https://${cardData.img}` }  alt = "a data card"></img>
                        <div className = "card-body">
                            <h5 className = "card-title">{ StringUtils.capitalizeWords(cardData.title) }</h5>
                            {
                                (cardData.desc !== undefined ) && <p className = "card-text">{ cardData.desc }</p>
                            }
                        </div>
                    </div>        
                )              
            }
            break;
        case "music":
            if (returnThisCard) {
                return(
                    <div key = { cardData.slug } className = { ["card", "mb-3", styles.cardContainer].join(" ") }>
                        <img className = "card-img-top" src = { `https://${cardData.img}` }  alt = "a data card"></img>
                        <div className = "card-body">
                            <h5 className = "card-title">{ StringUtils.capitalizeWords(cardData.title) }</h5>
                            {
                                (cardData.desc !== undefined ) && <a target = "_blank" rel = "noreferrer" href = { `https://${ cardData.audio }` } className = "card-text">{ cardData.desc }</a>
                            }
                        </div>
                    </div>        
                )              
            }
            break;
        default:
            // pass
    }
}

export default Card