import React from "react";
import Card from "./card";
import styles from "./cards.module.css";

const Cards = ({ selectedNavOptionLabel, projects, searchValue }) => {
    let docs = [];
    let key;

    // Get the documents for the selected category
    for (key in projects) {
        if (projects[key]["cat"] === selectedNavOptionLabel) {
            docs.push(projects[key]);
        }
    }

    // Sort the documents by `priority`
    docs.sort((a, b) => a["priority"] - b["priority"]);

    return(
        <div className = { styles.cardsContainer }>
            {
                docs.map((doc, i) => <Card key = { `doc-${i}` } selectedNavOptionLabel = { selectedNavOptionLabel } cardData = { doc } searchValue = { searchValue }></Card>)
            }
        </div>
    )
}

export default Cards