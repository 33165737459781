import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactMarkdown from 'react-markdown'
import { StringUtils, WebUtils } from "../scripts/utils";
import styles from "./markdown.module.css";

const DisplayProject = () => {
    const [currentProject, setCurrentProject] = useState(null);

    const navigate = useNavigate();

    const { slug } = useParams();
    let _filename = slug.replace(/-/g, "_");

    useEffect(() => {
        // Set the command string to get this article
        let _action = '{"action": "get-project", "file": "' + _filename + '"}';

        // Call the articles service
        WebUtils.request(process.env.REACT_APP_WEBSERVICES_URL, _action).then((_data) => {
        // Fetched data may have carriage return and newline characters
        _data = StringUtils.cleanCRNL(_data);
        let _dataJSON = JSON.parse(_data);

        setCurrentProject(_dataJSON);
        });
    }, [_filename]);

    return(
        <div className = { styles.markdownContainer }>
            <div className = "text-end">
                <button type = "button" className = "d-inline-block btn-close me-3 mt-3" aria-label = "close" onClick = {() => { navigate(process.env.REACT_APP_PATH) }}></button>
            </div>
            <div>
                { null !== currentProject ? <ReactMarkdown>{ currentProject.body }</ReactMarkdown> : <div></div> }
            </div>
        </div>
    )   
}

export default DisplayProject